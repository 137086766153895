<template>
  <div>
    <img
      v-if="provider === PAYMENT.PROVIDER.VISA"
      height="20"
      src="@/assets/payment-methods/visa.png"
      alt="visa"
    />
    <img
      v-else-if="provider === PAYMENT.PROVIDER.MASTER_CARD"
      height="30"
      src="@/assets/payment-methods/mastercard.png"
      alt="mastercard"
    />
    <img
      v-else-if="provider === PAYMENT.PROVIDER.AMERICAN"
      height="30"
      src="@/assets/payment-methods/american-express.png"
      alt="american"
    />
  </div>
</template>

<script>
import { PAYMENT } from '../../constants/PAYMENT'

export default {
  name: 'PaymentProviderImage',
  props: {
    provider: {
      type: String,
      required: true,
      validator: value => Object.values(PAYMENT.PROVIDER).includes(value),
    },
  },
  data() {
    return {
      PAYMENT,
    }
  },
}
</script>

<style scoped lang="scss"></style>
