<template>
  <AppButtonCard label="Add" @click="$emit('click')">
    <div class="summary-add-payment-card">
      <PaymentProviderImage :provider="PAYMENT.PROVIDER.VISA" />
      <PaymentProviderImage :provider="PAYMENT.PROVIDER.MASTER_CARD" />
      <PaymentProviderImage :provider="PAYMENT.PROVIDER.AMERICAN" />
    </div>
  </AppButtonCard>
</template>

<script>
import { PAYMENT } from '../../constants/PAYMENT'
import AppButtonCard from '../AppButtonCard.vue'
import PaymentProviderImage from './PaymentProviderImage.vue'

export default {
  name: 'AddPaymentCard',
  components: { AppButtonCard, PaymentProviderImage },
  data() {
    return { PAYMENT }
  },
}
</script>

<style scoped lang="scss">
.summary-add-payment-card {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 0.7rem;
  align-items: center;
}
</style>
