<template>
  <div
    class="app-card-clickable payment-card"
    tabindex="0"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <div v-if="payment.brand || $listeners.delete" class="payment-card-header">
      <PaymentProviderImage v-if="payment.brand" :provider="payment.brand" />
      <IconDelete
        v-if="$listeners.delete"
        style="color: var(--color-primary); margin-left: auto"
        @click="$emit('delete')"
      />
    </div>
    <div v-if="payment.lastFour" class="payment-card-lastfour">
      <p v-for="el in 3" :key="el" style="font-family: DDin; font-size: 24px">
        ****
      </p>
      <p style="font-size: 16px">{{ payment.lastFour }}</p>
    </div>
    <p class="payment-card-name ellipsis">{{ payment.name }}</p>
  </div>
</template>

<script>
import PaymentProviderImage from './PaymentProviderImage.vue'
import IconDelete from '../icons/common/IconDelete.vue'

export default {
  name: 'PaymentCard',
  props: { payment: Object },
  components: { PaymentProviderImage, IconDelete },
}
</script>

<style scoped lang="scss">
.payment-card {
  display: grid;
  gap: 1.2rem;
}
.payment-card-header {
  display: flex;
  align-items: center;
}
.payment-card-name {
  font-size: 13px;
  font-family: LuciferSans;
  text-transform: uppercase;
  letter-spacing: calc(13px / 10);
}
.payment-card-lastfour {
  display: grid;
  grid-template-columns: auto auto auto auto 1fr;
  gap: 1.75rem;
  letter-spacing: 0.5px;
  line-height: 1;
  height: 0.75rem;
}
</style>
