import { render, staticRenderFns } from "./PaymentProviderImage.vue?vue&type=template&id=1ceea28a&scoped=true"
import script from "./PaymentProviderImage.vue?vue&type=script&lang=js"
export * from "./PaymentProviderImage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ceea28a",
  null
  
)

export default component.exports